import { createApp } from "vue";
import App from "./App.vue";
import "./assets/styles/style.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import routes from "./router/index";
import { createPinia } from "pinia";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import "./assets/styles/vue-multiselect.css";
import "./assets/styles/theme.css";
import Popper from "vue3-popper";
import Vue3Tour from "vue3-tour";
import "vue3-tour/dist/vue3-tour.css";
import VueGtag from "vue-gtag";
import VueHighlightJS from "vue3-highlightjs";
import "highlight.js/styles/dracula.css";
import VuePerformance from "vue-performance";
import vue3GoogleLogin from "vue3-google-login";

import { useStore } from "./store/index";

import axios from "axios";

import i18n from "./i18n";

library.add(far, fas, fab);

const pinia = createPinia();
const app = createApp(App).use(i18n);

app.use(VueHighlightJS);
app.use(pinia);
app.use(routes);
app.use(VueTelInput);
app.use(Vue3Tour);
app.use(Popper);
app.use(vue3GoogleLogin, {
    clientId: "946870147174-bc57mqn96cpg31f07ab1jhrju1n24uh8.apps.googleusercontent.com",
    // scope: 'email',
    // prompt: 'consent',
});
app.use(VuePerformance, {
    // VuePerformance options
});
// app.use(VueWriter)
app.use(
    VueGtag,
    {
        appName: "GoPlatform",
        pageTrackerScreenviewEnabled: true,
        config: {
            id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
        },
    },
    routes,
);

// app.use(store);
app.component("font-awesome-icon", FontAwesomeIcon);

async function init() {
    // Access the state after the action completes
    let isLogged = false;
    await axios
        .get("https://server.go-platform.com/user/isLoggedIn", {
            withCredentials: true,
        })
        .then((response) => {
            isLogged = response.data.isLogged;
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    switch (isLogged) {
        case "user":
            console.log("user logged");
            isLogged = true;
            break;
        case "admin":
            console.log("admin logged");
            isLogged = true;
            break;
        case "none":
            isLogged = false;

            break;
        default:
            break;
    }
    return isLogged;
}
const Store = useStore();

routes.beforeEach(async (to, from, next) => {
    const destination = await to.matched.some((record) => {
        console.log({ record });
        record.path.includes("/previewAssessment");
    });
    if (destination) {
        next();
        return;
    }
    console.log({ destination });
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
    document.title = `${to.meta.title} | GO-Platform`;
    let isLoggedin = await init();
    console.log({ isLoggedMain: isLoggedin, requiresAuth });
    if (document.location.pathname === "/preview-project") {
        next();
    }
    if (isLoggedin && requiresAdmin) {
        next();
    } else if (requiresAuth && !isLoggedin) {
        // Redirect to the login page or any other appropriate action
        next("/");
        Store.userLogged();
    } else if (isLoggedin && !requiresAuth) {
        // Redirect the logged-in user to an appropriate page (e.g., dashboard)
        console.log("redirecting to dashboard");
        next("/dashboard");
    } else {
        // Continue with the navigation
        next();
    }
});

app.config.productionTip = false;
app.config.devtools = true;

app.mount("#app");
