import { createRouter, createWebHistory } from "vue-router";
// import SecurityView from "../views/SecurityView.vue";
// import BoardsView from "../views/BoardsView.vue";
//import PersonalityTest from '../views/PersonalityTest.vue'
// import candidateProfile from "../views/candidateprofile.vue";
// import TeamCalendar from "../views/Calendar.vue";
// import ChatRoom from "../views/ChatRoom.vue";
// import LineChart from "../views/LineChart.vue";
// import MyJobs from "../views/MyJobs.vue";
// import AssessementPreview from "../components/AssessementPreview.vue";
// import ArchiveView from "../views/ArchiveView.vue";
// import ActivityView from "../views/ActivityView.vue";
// import subscriptionsComponent from "../components/Subscription.vue";

import TalentProfile from "../views/TalentProfile.vue";

const routes = [
    {
        path: "/anti-cheat/:id",
        name: "anticheat",
        component: () => import("../views/CheatingCard.vue"),
        meta: {
            title: "anti-cheat",
            requiresAuth: true,
        },
    },
    {
        path: "/dropdown",
        name: "DropdownView",
        component: () => import("../views/DropdownView.vue"),
        meta: {
            title: "DropdownView",
            requiresAuth: false,
        },
    },
    {
        path: "/profile",
        name: "MarketPlaceProfile",
        component: () => import("../views/MarketPlaceProfile.vue"),
        meta: {
            title: "MarketPlaceProfile",
            requiresAuth: false,
        },
    },
    {
        path: "/privacy-policy",
        name: "privacy policy",
        component: () => import("../views/PrivacyPolicy.vue"),
        meta: {
            title: "privacy policy",
        },
    },
    {
        path: "/invite",
        name: "InviteC",
        component: () => import("../views/Invite.vue"),
        meta: {
            title: "Invite",
        },
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import("../views/AdminDashboard.vue"),
        meta: {
            title: "admin",
            requiresAdmin: true,
        },
    },
    {
        path: "/request-service",
        name: "request service",
        component: () => import("../views/RequestServiceView.vue"),
        meta: {
            title: "request service",
            requiresAdmin: false,
        },
    },
    {
        path: "/adminBlogs",
        name: "adminBlogs",
        component: () => import("../views/AdminBlogs.vue"),
        meta: {
            title: "adminBlogs",
            requiresAdmin: true,
        },
    },
    {
        path: "/companies",
        name: "companies",
        component: () => import("../views/CompaniesBoard.vue"),
        meta: {
            title: "Manage companies",
            requiresAdmin: true,
        },
    },
    {
        path: "/addBlogs",
        name: "addBlogs",
        component: () => import("../views/AddBlog.vue"),
        meta: {
            title: "addBlogs",
            requiresAdmin: true,
        },
    },
    {
        path: "/updateBlog/:id",
        name: "updateBlog",
        component: () => import("../views/UpdateBlog.vue"),
        meta: {
            title: "updateBlog",
            requiresAdmin: true,
        },
    },
    {
        path: "/categories",
        name: "categories",
        component: () => import("../views/AdminCategories.vue"),
        meta: {
            title: "adminCategories",
            requiresAdmin: true,
        },
    },
    {
        path: "/addCategory",
        name: "addCategory",
        component: () => import("../views/AddCategory.vue"),
        meta: {
            title: "addCategory",
            requiresAdmin: true,
        },
    },
    {
        path: "/updateCategory/:id",
        name: "updateCategory",
        component: () => import("../views/UpdateCategory.vue"),
        meta: {
            title: "updateCategory",
            requiresAdmin: true,
        },
    },

    {
        path: "/blogs",
        name: "BlogsPage",
        component: () => import("../views/BlogsView.vue"),
        meta: {
            title: "blogs",
        },
    },
    {
        path: "/job-position-preview",
        name: "JobPosPreview",
        component: () => import("../views/JobPosPreview.vue"),
        meta: {
            title: "preview job position",
        },
    },
    {
        path: "/blog/:id",
        name: "DetailBlog",
        component: () => import("../views/DetailBlog.vue"),
        meta: {
            title: "blog",
        },
    },

    {
        path: "/talent/:id",
        name: "Talent",
        component: TalentProfile,
        meta: {
            title: "Talent profile",
        },
    },
    {
        path: "/",
        name: "LandingPage",
        component: () => import("../views/LandingPage.vue"),
        meta: {
            title: "Home",
            requiresAuth: false,
        },
    },
    {
        path: "/ContactUs",
        name: "ContactUs",
        component: () => import("../views/ContactUsView.vue"),
        meta: {
            title: "Contact us",
            requiresAuth: false,
        },
    },
    {
        path: "/assessments-library/:cat",
        name: "assessments-library",
        component: () => import("../views/AssessmentLibrary.vue"),
        meta: {
            title: "Assessment Tests",
            requiresAuth: false,
        },
    },

    {
        path: "/SciencePage",
        name: "SciencePage",
        component: () => import("../views/SciencePage.vue"),
        meta: {
            title: "Science Page",
            requiresAuth: false,
        },
    },

    {
        path: "/Terms-of-use",
        name: "TermsPage",
        component: () => import("../views/TermsPage.vue"),
        meta: {
            title: "Term of use Page",
            requiresAuth: false,
        },
    },
    {
        path: "/tests/programming/testName",
        name: "TestName",
        component: () => import("../views/TestPage.vue"),
        meta: {
            title: "Assessment Tests",
            requiresAuth: false,
        },
    },
    {
        path: "/Talents",
        name: "NewLandingPage",
        component: () => import("../views/NewLandingPage.vue"),
        meta: {
            title: "Landing",
        },
    },
    {
        path: "/planning",
        name: "PlanningPage",
        component: () => import("../views/PlanningView.vue"),
        meta: {
            title: "Planing",
            requiresAuth: false,
        },
    },
    {
        path: "/pricing",
        name: "PricingPage",
        component: () => import("../views/PricingView.vue"),
        meta: {
            title: "Pricing",
        },
    },
    {
        path: "/contact",
        name: "contactUs",
        component: () => import("../views/contactUs.vue"),
        meta: {
            title: "Get in touch",
        },
    },
    {
        path: "/register",
        name: "RegisterPage",
        component: () => import("../views/RegisterPage.vue"),
        meta: {
            title: "Create Account",
            requiresAuth: false,
        },
    },

    {
        path: "/refferal",
        name: "RefferalView",
        component: () => import("../views/RefferalView.vue"),
        meta: {
            title: "Thank you!",
            requiresAuth: true,
        },
    },
    {
        path: "/New_Pass",
        name: "NewPassword",
        component: () => import("../views/NewPassword.vue"),
        meta: {
            title: "New password",
        },
    },

    // {
    //     path: "/library-partial",
    //     name: "library-partial",
    //     component: () => import("../views/PartialLibraryView.vue"),
    //     meta: {
    //         title: "Assessements Library",
    //         requiresAuth: true,
    //         checkCredit: true,
    //     },
    // },
    {
        path: "/library",
        name: "library",
        component: () => import("../views/LibraryView.vue"),
        meta: {
            title: "Assessements Library",
            requiresAuth: true,
            // requiresAuth: true,
        },
    },
    {
        path: "/market",
        name: "market",
        component: () => import("../views/MarketPlace.vue"),
        meta: {
            title: "market",
            //requiresAuth: true,
            // requiresAuth: true,
        },
    },
    // {
    //     path: "/security",
    //     name: "security",
    //     component: SecurityView,
    //     meta: {
    //         title: "Security",
    //         requiresAuth: true,
    //     },
    // },
    {
        path: "/candidates",
        name: "candidates",
        component: () => import("../views/CandidatesView.vue"),
        meta: {
            title: "Candidates",
            requiresAuth: true,
        },
    },
    {
        path: "/custom-question",
        name: "questions",
        component: () => import("../components/CustomQuestionLib.vue"),
        meta: {
            title: "questions",
            requiresAuth: false,
        },
    },
    {
        path: "/payement",
        name: "payement",
        component: () => import("../views/PayementView2.vue"),
        meta: {
            title: "Payement",
            requiresAuth: true,
        },
    },
    {
        path: "/buttons",
        name: "buttons",
        component: () => import("../views/TestButtons.vue"),
        meta: {
            title: "buttons",
            requiresAuth: false,
        },
    },
    {
        path: "/boards",
        name: "boards",
        component: () => import("../views/DashboardView"),
        meta: {
            title: "Boards",
            requiresAuth: true,
        },
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/PartialLibraryView.vue"),
        meta: {
            title: "Dashboard",
            requiresAuth: true,
        },
    },
    {
        path: "/board/:id",
        name: "board",
        component: () => import("../views/DashboardView"),
        meta: {
            title: "Board",
            requiresAuth: true,
        },
    },
    // {
    //     path: "/activity",
    //     name: "activity",
    //     component: ActivityView,
    //     meta: {
    //         title: "Dashboard",
    //         requiresAuth: true,
    //     },
    // },
    {
        path: "/charts",
        name: "charts",
        component: () => import("../views/ChartView"),
        meta: {
            title: "New password",
        },
    },

    {
        path: "/personalitytest",
        name: "PersonalityTest",
        component: () => import("../views/PersonalityTest_copy.vue"),
        meta: {
            title: "Profile",
            requiresAuth: true,
        },
    },
    {
        path: "/payementForm",
        name: "PayementForm",
        component: () => import("../views/PayementForm.vue"),
        meta: {
            title: "Payement",
            requiresAuth: true,
        },
    },
    // {
    //     path: "/CardPerformance",
    //     name: "CardPerformance",
    //     component: CardPerformance,
    //     meta: {
    //         title: "Card Performance",
    //         requiresAuth: true,
    //     },
    // },

    {
        path: "/companyprofile",
        name: "companyProfile",
        component: () => import("../views/CompanyProfile.vue"),
        meta: {
            title: "Company",
            requiresAuth: true,
        },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("../views/NotFoundView.vue"),
        meta: {
            title: "Not Found",
        },
    },
    // {
    //     path: "/candidateProfile",
    //     name: "candidateProfile",
    //     component: candidateProfile,
    //     meta: {
    //         title: "Candidate",
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: "/calendar",
    //     name: "TeamCalendar",
    //     component: TeamCalendar,
    //     meta: {
    //         title: "Calendar",
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: "/ChatRoom",
    //     name: "ChatRoom",
    //     component: ChatRoom,
    //     meta: {
    //         title: "New password",
    //     },
    // },
    {
        path: "/login",
        name: "LogIn",
        component: () => import("../views/LogIn.vue"),
        meta: {
            title: "Login",
        },
    },
    // {
    //     path: "/linechart",
    //     name: "LineChart",
    //     component: LineChart,
    //     meta: {
    //         title: "New password",
    //     },
    // },
    {
        path: "/invoice",
        name: "invoice",
        component: () => import("../views/InvoiceView.vue"),
        meta: {
            title: "Invoice",
            requiresAuth: true,
        },
    },
    // {
    //     path: "/archive",
    //     name: "archive",
    //     component: ArchiveView,
    //     meta: {
    //         title: "Archive",
    //         requiresAuth: true,
    //     },
    // },
    {
        path: "/manage",
        name: "manage",
        component: () => import("../views/ManageView.vue"),
        meta: {
            title: "Manage",
            requiresAuth: true,
        },
    },

    // {
    //     path: "/preview",
    //     name: "preview",
    //     component: AssessementPreview,
    //     meta: {
    //         title: "New password",
    //     },
    // },
    // {
    //     path: "/subscriptions",
    //     name: "Subscriptions",
    //     component: subscriptionsComponent,
    //     props: true, // Enable passing props to the component
    // },
    {
        path: "/InvitedCoworker",
        name: "InvitedCoworker",
        component: () => import("../views/InvitedCoworker.vue"),
        props: true, // Enable passing props to the component
    },
    {
        path: "/CompareTalent",
        name: "CompareTalent",
        component: () => import("../views/compareView.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "Compare",
            requiresAuth: true,
        },
    },
    {
        path: "/ChangePassword",
        name: "ChangePassword",
        component: () => import("../views/ChangePassword.vue"),
        meta: {
            title: "Change password",
        },
    },
    {
        path: "/newAssessment",
        name: "NewAssessement",
        component: () => import("../views/NewAssessment.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "new assessment",
            requiresAuth: true,
        },
    },
    {
        path: "/candidate",
        name: "CandidateActivity",
        component: () => import("@/components/CandidateActivity.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "candidate",
            requiresAuth: true,
        },
    },
    {
        path: "/previewAssessment",
        name: "QuestionsSample",
        component: () => import("../views/QuestionsSample.vue"),
        meta: {
            title: "preview assessment",
            requiresAuth: true,
        },
        props: true, // Enable passing props to the component
    },
    {
        path: "/preview-project",
        name: "QuestionsSample",
        component: () => import("../views/PreviewProject.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "preview-project",
            requiresAuth: false,
        },
    },
    {
        path: "/settings",
        name: "profile settings",
        component: () => import("../views/SettingsView.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "settings",
            requiresAuth: true,
        },
    },
    {
        path: "/Tour",
        name: "ProductTour",
        component: () => import("../views/ProductTour.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "Product Tour",
            requiresAuth: false,
        },
    },
    {
        path: "/Terms",
        name: "Terms",
        component: () => import("../views/PolicyTerms.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "Policy & terms of use",
            requiresAuth: false,
        },
    },
    {
        path: "/BPO-and-outsourcing",
        name: "BPO-and-outsourcing",
        component: () => import("../views/OutSourcingView.vue"),
        props: true, // Enable passing props to the component
        meta: {
            title: "BPO-and-outsourcing",
            requiresAuth: false,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
/*const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router*/
